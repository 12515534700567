import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import { CalendarDaysIcon, PlusIcon } from "@heroicons/react/16/solid";
import Modal from "../../../shared/components/UIElements/Modal";
import FormFields from "../../../slots/components/creation-wizard/NewSlotFormFields";
import Button from "../../../shared/components/FormElements/Button";
import { validationSchema } from "../../../slots/components/creation-wizard/validationSchema";
import useFetch from "../../../shared/hooks/useFetch";

const NewSlotButton = ({ setSlots }) => {
	const [open, setOpen] = useState(false);
	const { user } = useUser();
	const createSlot = useFetch();

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			const date = values.unformattedDate.format("YYYY-MM-DD");
			const slotDetails = {
				...values,
				date,
				creatorId: user.id,
			};

			const response = await createSlot("/api/slots?status=open", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(slotDetails),
			});

			const updatedSlots = await createSlot(
				`/api/slots/available/${user.id}`,
			);
			setSlots(updatedSlots);

			toast.success("Slot aangemaakt", {
				position: "bottom-center",
			});
			setOpen(false);
		} catch (error) {
			console.error("Error creating slot:", error);
			toast.error("Er ging iets mis bij het aanmaken van de slot", {
				position: "bottom-center",
			});
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<>
			<button
				className="inline-flex items-center gap-x-1 text-sm font-semibold text-brand-intense hover:text-brand-700 mb-1.5"
				onClick={(e) => {
					e.preventDefault();
					setOpen(true);
				}}
			>
				<PlusIcon className="size-4" />
				Nieuwe plek
			</button>
			<Modal open={open} setOpen={setOpen}>
				<h3 className="text-xl leading-6 font-bold text-gray-900 flex items-center gap-2 px-8">
					<CalendarDaysIcon className="size-5 text-brand-intense" />
					Lege agendaplek melden
				</h3>

				<Formik
					initialValues={{
						unformattedDate: "",
						startTime: "",
						endTime: "",
						behandelaar: "",
						location: "",
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{({ errors, touched, submitForm, values }) => (
						<>
							<Form>
								<div className="px-8 pb-6">
									<FormFields
										errors={errors}
										touched={touched}
										values={values}
									/>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
									<Button
										type="button"
										onClick={submitForm}
										size="medium"
										classNames="w-full justify-center sm:w-fit"
									>
										Plek aanmaken
									</Button>
									<Button
										type="button"
										onClick={() => setOpen(false)}
										ghost
										size="medium"
										classNames="w-full justify-center sm:w-fit"
									>
										Annuleren
									</Button>
								</div>
							</Form>
						</>
					)}
				</Formik>
			</Modal>
		</>
	);
};

export default NewSlotButton;
