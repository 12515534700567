import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

export const validationSchemaStepOne = Yup.object().shape({
	firstName: Yup.string().required("Voornaam is verplicht"),
	lastName: Yup.string().required("Achternaam is verplicht"),
	phoneNumber: Yup.string()
		.test("phone-number", "Telefoonnummer is niet geldig", (value) =>
			isValidPhoneNumber(value),
		)
		.required("Telefoonnummer is verplicht"),
	phoneNumber2: Yup.string()
		.matches(/^\+?[1-9]\d{10}$|^06\d{8}$/, "Telefoonnummer is niet geldig")
		.nullable(),
	phoneNumber3: Yup.string()
		.matches(/^\+?[1-9]\d{10}$|^06\d{8}$/, "Telefoonnummer is niet geldig")
		.nullable(),
	patientnummer: Yup.string().required("Patiëntnummer is verplicht"),
});

export const validationSchema = Yup.object().shape({
	...validationSchemaStepOne.fields,
	slot: Yup.string().required("Er moet minimaal 1 plek geselecteerd worden"),
});
